<template>
  <el-row class="passwordinput">
    <el-card shadow="never">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-col :span="24">
          <el-form-item>
            <el-form-item>
              <h4>新しいパスワードを入力してください。</h4>
            </el-form-item>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="pass_word">
            <el-input
              type="password"
              v-model="ruleForm.pass_word"
              autocomplete="off"
              placeholder="Password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="pass_word_check">
            <el-input
              type="password"
              v-model="ruleForm.pass_word_check"
              autocomplete="off"
              placeholder="Password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >送信</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <h5>
              Password思い出しましたか？
              <el-link href="/#/signin" :underline="false" type="primary"
                >サインイン</el-link
              >
              へ
            </h5>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
  </el-row>
</template>

<script>
import { ResetPassword } from "api/user";
import { Message } from "element-ui";
export default {
  name: "PasswordInput",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("パスワードを入力してください"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("pass_word_check");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("もう一度パスワードを入力してください"));
      } else if (value !== this.ruleForm.pass_word) {
        callback(new Error("パスワードが一致しません"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        email: this.$route.query.email,
        rand_num: this.$route.query.rand_num,
        pass_word: "",
        pass_word_check: "",
      },
      rules: {
        pass_word: [{ validator: validatePass, trigger: "blur" }],
        pass_word_check: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    ResetPassword() {
      let values = {};
      values["email"] = this.ruleForm.email;
      values["pass_word"] = this.ruleForm.pass_word;
      ResetPassword(values)
        .then((res) => {
          if (res.data.status === 1) {
            clearTimeout(this.timer); //清除延迟执行
            this.timer = setTimeout(() => {
              this.$router.push({ path: "Signin" });
            }, 3000);
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "erroe",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.ResetPassword();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-button {
  width: 100%;
}

.passwordinput {
  margin: 0;
  padding: 5% 20% 0 58%;
  background-image: url("../../assets/img/5626873.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
</style>
